<template>
  <div v-myTitle :data-title="textTitle">
    <div class="matrix">
<!--      <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 100px">{{ this.$title }}</h1>
      <div class="done" v-if="status == 1">您已完成测评</div>
      <div v-if="this.$store.state.userInfo.type == 2" style="margin-top: 10px">评分者：{{ this.$store.state.staffInfo.name }}</div>
      <div class="sqare">
        <div class="sqareItem" v-for="(item, index) in matrixtList" :key="index">
          <div class="onetitle" :style="{ backgroundColor: item.color }">
            {{ item.name }}
          </div>
          <div class="oneCount">{{ item.count }}</div>
        </div>
      </div>
      <button class="detail" @click="goNet">查看蛛网图</button>
    </div>
  </div>
</template>

<script>
import { getScore } from '@/request/api';

export default {
  name: "Matrix",
  data() {
    return {
      textTitle: this.$title,
      matrixtList: [],
      status: "",
    };
  },
  created() {
    this.getScore();
  },
  methods: {
    goNet() {
      this.$router.replace("/cobweb");
    },
    getScore() {
      let staff_id = 0
      if (this.$store.state.userInfo.type == 2) {
        staff_id = this.$store.state.staffInfo.id
      }
      console.log(staff_id, 'staff_id')
      getScore({
        staff_id: staff_id
      }).then((res) => {
        this.matrixtList = res.data.list
        this.status = res.data.status
      })
    }
  },
};
</script>

<style>
.title {
  color: #0089c4;
  margin: 0;
}
.done {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 20px auto;
}
.sqare {
  width: 80%;
  margin: 20px auto;
}
.sqareItem {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: flex;
}
.onetitle {
  width: 80%;
  margin-right: 10px;
  font-size: 0.8rem;
}

.detail {
  width: 60%;
  height: 40px;
  margin: 30px auto;
  display: block;
  border-radius: 5px;
}
</style>
